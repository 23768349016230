//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [{ icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" }],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    llista00: [],
    llistaitemscount: 0,
    modal: false,
    dialog: false,
    dialogEsborrar: false,
    form: false,
    rules: {
      length: (len) => (v) =>
        (v || "").length >= len || `Invalid character length, required ${len}`,
      required: (v) => !!v || "This field is required",
    },
  }),
  created() {},
  mounted() {
    if (sessionStorage["sessDocId"] != 0) {
      this.omplirEdits(
        sessionStorage["sessDocYear"],
        sessionStorage["sessDocId"]
      );
    } else {
      this.llista00.docDate = this.getToday();
      this.llista00.description = "New";
      this.setFocus("TextField.Description");
    }
  },
  methods: {
    getToday() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10);
    },
    goBack() {
      this.$router.push("/listitems00list");
    },
    goMenu(action) {
      switch (action) {
        case "logout":
          this.goLogOut();
          break;
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
      }
    },
    setFocus(nomCamp) {
      setTimeout(() => {
        if (document.getElementById(nomCamp) != null) {
          document.getElementById(nomCamp).focus();
        }
      }, 200);
    },
    omplirEdits(DocYear, DocId) {
      axios
        .post(api.URL() + "/api/v1/gllistagarticles00_getbyid", {
          token: sessionStorage.getItem("Token"),
          docYear: parseInt(DocYear),
          docId: parseInt(DocId),
        })
        .then((response) => {
          this.llista00 = response.data;
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    appendUpdateRecord() {
      if (sessionStorage["sessDocId"] == 0) {
        axios
          .post(api.URL() + "/api/v1/gllistagarticles00_append", {
            token: sessionStorage.getItem("Token"),
            userId: parseInt(sessionStorage["UserId"]),
            docDate: this.llista00.docDate,
            description: this.llista00.description,
            text: this.llista00.text,
          })
          .then((response) => {
            console.log(response);
            (sessionStorage["sessDocYear"] = response.data.docYear),
              (sessionStorage["sessDocId"] = response.data.docId),
              this.$router.push("/listitems01list");
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      } else {
        axios
          .post(api.URL() + "/api/v1/gllistagarticles00_update", {
            token: sessionStorage.getItem("Token"),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docId: parseInt(sessionStorage["sessDocId"]),
            docDate: this.llista00.docDate,
            description: this.llista00.description,
            text: this.llista00.text,
          })
          .then((response) => {
            console.log(response);
            this.$router.push("/listitems01list");
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    checkDelete() {
      if (parseInt(sessionStorage["sessDocId"]) != 0) {
        axios
          .post(api.URL() + "/api/v1/gllistagarticles01_countbyid", {
            token: sessionStorage.getItem("Token"),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docId: parseInt(sessionStorage["sessDocId"]),
          })
          .then((response) => {
            this.llistaitemscount = response.data.count;
            if (this.llistaitemscount != 0) {
              this.dialogEsborrar = true;
            } else {
              this.deleteRecord();
            }
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      } else {
        this.goBack();
      }
    },
    deleteRecord() {
      if (parseInt(sessionStorage["sessDocId"]) != 0) {
        axios
          .post(api.URL() + "/api/v1/gllistagarticles00_delete", {
            token: sessionStorage.getItem("Token"),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docId: parseInt(sessionStorage["sessDocId"]),
          })
          .then((response) => {
            if (response.error == null) {
              this.goBack();
            } else {
              this.msgAlert(response.error, true);
            }
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      } else {
        this.goBack();
      }
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
