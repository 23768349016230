import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VToolbar,{attrs:{"color":"blue lighten-2","cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" Capçalera "),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"blue lighten-2","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}])},[_c(VList,_vm._l((_vm.menuItems),function(item,index){return _c(VListItem,{key:index},[_c(VListItemIcon,[_c(VIcon,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c(VForm,{ref:"form",staticClass:"pa-4 pt-6",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"300px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"rules":[_vm.rules.length(1)],"label":"Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.llista00.docDate),callback:function ($$v) {_vm.$set(_vm.llista00, "docDate", $$v)},expression:"llista00.docDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(VDatePicker,{attrs:{"locale":"es"},model:{value:(_vm.llista00.docDate),callback:function ($$v) {_vm.$set(_vm.llista00, "docDate", $$v)},expression:"llista00.docDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"blue lighten-2"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c(VDialog,{attrs:{"max-width":"300"},model:{value:(_vm.dialogEsborrar),callback:function ($$v) {_vm.dialogEsborrar=$$v},expression:"dialogEsborrar"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Atenció ")]),_c(VCardText,[_vm._v(" En aquesta llista hi ha "+_vm._s(_vm.llistaitemscount)+" registres, segur que vols esborar-la. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue lighten-2","text":""},on:{"click":function($event){_vm.deleteRecord();
              _vm.dialogEsborrar = false;}}},[_vm._v("Si")]),_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){_vm.dialogEsborrar = false}}},[_vm._v("No")])],1)],1)],1),_c(VTextField,{staticStyle:{"min-height":"96px"},attrs:{"id":"TextField.Description","rules":[_vm.rules.length(1)],"filled":"","counter":"50","label":"Descripció"},model:{value:(_vm.llista00.description),callback:function ($$v) {_vm.$set(_vm.llista00, "description", $$v)},expression:"llista00.description"}}),_c(VTextarea,{attrs:{"filled":"","label":"Notes","rows":"4"},model:{value:(_vm.llista00.text),callback:function ($$v) {_vm.$set(_vm.llista00, "text", $$v)},expression:"llista00.text"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.form,"color":"blue lighten-2"},on:{"click":function($event){return _vm.appendUpdateRecord()}}},[_c(VIcon,[_vm._v("mdi-content-save")]),_vm._v(" Guardar i detall ")],1),_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.checkDelete()}}},[_c(VIcon,[_vm._v("mdi-delete")]),_vm._v(" Esborrar ")],1),_c(VSpacer)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }